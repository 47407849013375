/// example: https://stackoverflow.com/questions/3426404/create-a-hexadecimal-colour-based-on-a-string-with-javascript

export function hashCode(str: string): number {
  let hash = 0;
  for (let i = str.length - 1; i > 0; i--) {
    hash = str.charCodeAt(i) + ((hash << 6) - hash);
  }
  return hash;
}

export function intToRGB(size: number): string {
  const code = (size & 0x00FFFFFF).toString(16).toUpperCase();
  return '00000'.substring(0, 6 - code.length) + code;
}

export function stringToRGB(str: string) {
  return `#${intToRGB(hashCode(str))}`;
}

export function hexColor(hex: string = '', opacity?: number): string {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  return `rgba(${r},${g},${b}${opacity ? `,${opacity}` : ''})`;
}
