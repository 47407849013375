<ng-container *ngIf='hasPages'>
  <button (click)='pagePrev()' [disabled]='prevDisabled' class='page prev'>
    <svg-icon src='/assets/icons/arrow-left.svg'></svg-icon>
  </button>
  <ng-container *ngFor='let item of pages;trackBy:trackByFn'>
    <button (click)='pageSelect(item)'
            [class.selected]='isSelected(item)|async'
            [class]='item.sfx' class='page'>{{item?.label}}</button>
  </ng-container>
  <button (click)='pageNext()' [disabled]='nextDisabled' class='page next'>
    <svg-icon src='/assets/icons/arrow-right.svg'></svg-icon>
  </button>
</ng-container>
