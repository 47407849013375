import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { AuthService } from '../../auth/services';
import { RoutePath } from '../../models';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshing$ = new Subject<boolean>();
  private isRefreshingToken = false;
  private apiUrl = `/api/`;
  private refreshUrl = `/api/auth/refresh`;

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const isApiRequest = request.url.includes(this.apiUrl);
    if (isApiRequest) {
      if (this.isRefreshingToken && !request.url.includes(this.refreshUrl)) {
        return this.refreshing$.pipe(mergeMap(() => next.handle(this.addTokenToRequest(request)).pipe(this.handleError)));
      }
      return next.handle(this.addTokenToRequest(request)).pipe(this.handleResponse, this.handleError);
    }
    return next.handle(request);
  }

  private handleError = catchError<HttpEvent<any>, any>(error => {
    if (error instanceof HttpErrorResponse) {
      switch ((<HttpErrorResponse>error).status) {
        case 401:
          this.router.navigate([RoutePath.login]).then();
          this.auth.clean();
          return of(null);
      }
    }
    return throwError(error);
  });

  private handleResponse = tap((response: any) => {
    if (response && response instanceof HttpResponse) {
      const isApiRequest = response.url?.includes('api.');
      if (isApiRequest) {
      }
    }
  });

  private addTokenToRequest = (request: HttpRequest<any>): HttpRequest<any> => {
    const { production } = environment ?? {};
    let headers = request.headers;
    if (this.auth.isAuthenticated && !request.url.includes(this.refreshUrl)) {
      headers = headers.set('Authorization', `JWT ${this.auth.token.access_token}`);
    }
    return request.clone({ headers, withCredentials: production });
  };

  constructor(private router: Router, private auth: AuthService) {
  }
}
