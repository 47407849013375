import { ChangeDetectionStrategy, Component, HostBinding, Input, OnChanges, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { stringToRGB } from '../../../utils';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnChanges, OnDestroy {
  @Input() hash: string = '';
  @Input() url: string;
  @Input() size = 30;
  @Input() radius = `50%`;
  @Input() color: string;
  @Input() icon: string;
  @Input() placeholder: string;
  src = new ReplaySubject(1);

  @HostBinding('style')
  get style(): string {
    return `width:${this.size}px;height:${this.size}px;border-radius:${this.radius};`;
  }

  get fontSize(): string {
    return `${this.size / 2}px`;
  }

  get initials(): string {
    const { 0: a, length: l, [l - 1]: b } = this.hash ? this.hash : '';
    return `${a ?? ''}${b ?? ''}`;
  }

  get backgroundStyle(): string {
    const [a, b] = this.hash ?? 'ab';
    const color = this.color ? this.color : a || b ? stringToRGB(this.hash) : '#ccc';
    const placeholder = this.placeholder ? `background-image:url('${this.placeholder}');` : '';
    return `background-color:${color};border-radius:${this.radius};${placeholder}`;
  }

  private initImage(): void {
    if (this.url) {
      this.src.next(`url(${this.url})`);
    }
  }

  ngOnChanges(): void {
    this.initImage();
  }

  ngOnDestroy(): void {
    this.src.complete();
  }
}
