import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { marked } from 'marked';

const renderer = new marked.Renderer();

renderer.link = (href, title = '', text = '') => {
  const host = document?.baseURI;
  let link = href;
  let target = `target="_blank"`;
  if (href && /^#/.test(href)) {
    link = `${host}${href}`;
    target = '';
  }
  if (href?.includes(`{host}`)) {
    link = href.split(`{host}`).join(host);
  }
  return `<a href='${link}' title='${title}'${target}>${text}</a>`;
};

@Pipe({ name: 'markdown' })
export class MarkdownPipe implements PipeTransform {
  transform(value: string): SafeHtml {
    if (value === null) {
      return '';
    }
    const parsed = marked.parse(value, { renderer });
    return this.s.bypassSecurityTrustHtml(parsed);
  }

  constructor(private s: DomSanitizer) {
  }
}
