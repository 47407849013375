import { Injectable } from '@angular/core';
import { ComponentType } from '@angular/cdk/overlay';
import { Dialog, DialogRef } from '@angular/cdk/dialog';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../components';
import { ConfirmDialogData } from '../models';

@Injectable()
export class ConfirmDialogService {
  dialogRef: DialogRef<any>;

  public confirm<T>(confirm: ConfirmDialogData, c?: ComponentType<T>): Observable<DialogRef<T>> {
    const data: ConfirmDialogData = {
      title: confirm.title,
      description: confirm?.description ?? '',
      confirmText: confirm?.confirmText ?? 'Confirm',
      cancelText: confirm?.cancelText ?? 'Cancel',
      confirm: confirm?.confirm,
      cancel: confirm?.cancel,
      data: confirm?.data
    };
    const config = {
      data,
      backdropClass: 'dialog-backdrop',
      hasBackdrop: true,
      autoFocus: false,
      restoreFocus: false,
      closeOnNavigation: true
    };
    const component: ComponentType<any> = c ? c : ConfirmDialogComponent;
    this.dialogRef = this.d.open(component, config);
    return this.dialogRef.closed;
  }

  constructor(private d: Dialog) {
  }
}
