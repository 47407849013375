import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import {
  BehaviorSubject,
  catchError,
  filter,
  finalize,
  first,
  map,
  Observable,
  of,
  startWith,
  switchMap,
  tap,
  throwError
} from 'rxjs';
import {
  AccessToken,
  ApiService,
  AuthData,
  LoginData,
  RegistrationData,
  ResponseError,
  StorageKey,
  User
} from '../../api';
import { DataStorage, ToCrypto } from '../../utils';
import { NotificationService } from '../../notifications';
import { RoutePath } from '../../models';

@Injectable({ providedIn: 'root' })
export class AuthService extends DataStorage<AuthData> {
  errors$: BehaviorSubject<ResponseError> = new BehaviorSubject<ResponseError>(undefined!);
  crypto = new ToCrypto(StorageKey.access_token);

  private _token$: BehaviorSubject<AccessToken> = new BehaviorSubject<AccessToken>(undefined!);
  private _user$: BehaviorSubject<User> = new BehaviorSubject<User>(undefined!);
  private _tryToken = false;
  private _tryUser = false;

  get isAuthenticated(): boolean {
    const token = this.token;
    return typeof token?.access_token === 'string' && token?.access_token.length > 0;
  }

  get isAuthenticated$(): Observable<boolean> {
    return this.token$.pipe(map(t => t && typeof t?.access_token === 'string'));
  }

  set token(token: AccessToken) {
    this._token$.next(token);
    localStorage.setItem(StorageKey.access_token, this.crypto.stringify(token));
  }

  get token(): AccessToken {
    if (!this._token$.value && !this._tryToken) {
      const token = localStorage.getItem(StorageKey.access_token);
      if (token && token.length > 0) {
        try {
          this.token = this.crypto.parse(token);
        } catch (e) {
          console.log(e);
        }
      }
      this._tryToken = true;
    }
    return this._token$.value!;
  }

  set user(user: User) {
    this._user$.next(user);
    localStorage.setItem(StorageKey.user, JSON.stringify(user));
  }

  get user(): User {
    if (!this._user$.value && !this._tryUser) {
      const user = localStorage.getItem(StorageKey.user);
      if (user && user.length > 0) {
        try {
          this.user = JSON.parse(user);
        } catch (e) {
          console.log(e);
        }
      }
      this._tryUser = true;
    }
    return this._user$.value!;
  }

  get user$(): Observable<User> {
    return this._user$.pipe(startWith(this.user));
  }

  get token$(): Observable<AccessToken> {
    return this._token$.pipe(startWith(this.token));
  }

  login(data: LoginData): Observable<AuthData> {
    this.loading.next(true);
    return this.api.login(data).pipe(tap(this.dataEmit), tap(this.loginSuccess), catchError(this.loginError), finalize(this.loadingFinalize));
  }

  registration(data: RegistrationData): Observable<AuthData> {
    this.loading.next(true);
    return this.api.registration(data).pipe(tap(this.registrationSuccess), catchError(this.loginError), finalize(this.loadingFinalize));
  }

  logout(): void {
    this.clean();
    this.r.navigate([RoutePath.login]);
  }

  saveToken = (token: AccessToken): void => {
    this.token = token;
  };

  saveUser = (user: User): void => {
    this.user = user;
  };

  state = (): Observable<AuthData> => {
    return this.isAuthenticated$.pipe(filter(t => t), first(),
      switchMap(() => this.api.authState({ Authorization: `JWT ${this.token.access_token}` })),
      catchError(this.statusError));
  };

  dataEmit = (d: AuthData): void => {
    this.saveToken(d?.access_token);
    this.saveUser(d?.user);
    this.loading.next(false);
  };

  loginError = (e: Error): Observable<any> => {
    if (e instanceof HttpErrorResponse) {
      const { error, status } = e;
      if (status === 400) {
        this.errors$.next(error.error);
        this.n.error({ title: `Validation error`, message: error.error.message });
      }
      return of(null);
    }
    return throwError(() => e);
  };

  loginSuccess = (): void => {
    this.n.success({ title: 'Login was successful' });
    this.r.navigate([RoutePath.root]);
  };

  registrationSuccess = (): void => {
    this.n.success({ title: 'Registration was successful' });
    this.r.navigate([RoutePath.login]);
  };

  statusError = (e: HttpErrorResponse): Observable<any> => {
    this.logout();
    return of(null);
  };

  init(): void {
    this.subs.push(this.state().subscribe());
  }

  destroy(): void {
    super.destroy();
    this.reset();
  }

  clean() {
    this.token = undefined!;
    this.user = undefined!;
    localStorage.removeItem(StorageKey.access_token);
    localStorage.removeItem(StorageKey.user);
  }

  reset(): void {
    this.errors$.next(undefined!);
  }

  constructor(private api: ApiService, private r: Router, private n: NotificationService) {
    super();
  }
}
