import { init, Replay, routingInstrumentation, setTags } from '@sentry/angular-ivy';
import { BrowserTracing } from '@sentry/tracing';
import p from '../package.json';

export function sentryInit(): void {
  init({
    dsn: 'https://427a4a22e9ba5bf910bcef47c0c98ebf@sentry.neoninfra.xyz/6',
    integrations: [
      new BrowserTracing({
        tracePropagationTargets: ['localhost', 'skill-quest.neonlabs.org', 'skill-quest.neontest.xyz'],
        routingInstrumentation: routingInstrumentation
      }),
      new Replay()
    ],
    tracesSampleRate: 1.0
  });
  setTags({ version: p.version });
}
