<app-header>
</app-header>
<app-content>
  <nav class='flex flex-row gap-[18px] justify-between mb-[24px]'>
    <div class='flex flex-row gap-[8px]'>
      <a class='router-link' [routerLink]='candidates' routerLinkActive='active'>Candidates</a>
      <a class='router-link' [routerLink]='questions' routerLinkActive='active'>Questions</a>
      <a class='router-link' [routerLink]='sessions' routerLinkActive='active'>Sessions</a>
    </div>
    <div class='flex flex-row gap-[8px]'>
      <a class='router-link' [routerLink]='managers' routerLinkActive='active'>Managers</a>
    </div>
  </nav>
  <div class='flex flex-row gap-[32px]'>
    <div class='w-full'>
      <router-outlet></router-outlet>
    </div>
    <div>
      <app-account></app-account>
    </div>
  </div>
</app-content>
<app-footer></app-footer>
