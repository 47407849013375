import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '../../../auth/services';

@Component({
  templateUrl: './common-layout.component.html',
  styleUrls: ['./common-layout.component.sass'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class CommonLayoutComponent {
  constructor(public auth: AuthService) {
  }
}
