import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NotificationService } from './services/notification.service';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationComponent } from './components/notification/notification.component';
import { NotificationTemplateDirective } from './directives';


@NgModule({
  declarations: [NotificationsComponent, NotificationComponent, NotificationTemplateDirective],
  imports: [CommonModule, AngularSvgIconModule],
  exports: [NotificationsComponent],
  providers: [NotificationService]
})
export class NotificationsModule {
}
