import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmptyContentComponent {
  @Input() title = 'No data...';
  @Input() description = '';
}
