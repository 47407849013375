import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { ConfirmDialogData } from '../../models';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmDialogComponent {
  public confirm(): void {
    if (typeof this.data?.confirm === 'function') {
      this.data.confirm();
    }
    this.ref.close(true);
  }

  public close(): void {
    this.ref.close(false);
  }

  constructor(private ref: DialogRef, @Inject(DIALOG_DATA) public data: ConfirmDialogData) {
  }
}
