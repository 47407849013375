<div class='mr-[16px]'>
  <svg-icon [src]='icon'></svg-icon>
</div>
<div class='w-full mr-2'>
  <ng-template appNotificationTemplate></ng-template>
  <ng-container *ngIf='isContentText'>
    <h3 class='text-base white'>{{item.title}}</h3>
    <p *ngIf='item.message' class='notification-content' [innerHTML]='item.message'></p>
  </ng-container>
</div>
<div *ngIf='item.close' class='self-start py-[4px]'>
  <a (click)='onClose()' class='button-close'>
    <svg-icon src='/assets/icons/close.svg'></svg-icon>
  </a>
</div>
