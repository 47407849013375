import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-markdown-content',
  templateUrl: './markdown-content.component.html',
  styleUrls: ['./markdown-content.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MarkdownContentComponent implements OnInit {
  @Input() title: string = '';
  @Input() content: string = '';
  @Input() description: string = '';
  @Input() loading: Observable<boolean> = of(false);
  @Input() shortDescription = false;
  @Input() shortDescriptionControl = true;
  private isShort = false;

  get showDescription(): string {
    const c = this.description;
    if (this.isShort) {
      const ca = c.split(' ');
      return ca?.length > 20 ? `${ca.slice(0, 20).join(' ')}...` : c;
    }
    return c;
  }

  get showControl(): boolean {
    return this.shortDescription && this.shortDescriptionControl;
  }

  get descriptionBlur(): string {
    return this.isShort ? 'content-blur' : '';
  }

  get fullContentText(): string {
    return `${this.isShort ? 'More' : 'Less'}...`;
  }

  onHandleFullDescription(): void {
    this.isShort = !this.isShort;
  }

  ngOnInit() {
    if (this.shortDescription) {
      this.isShort = true;
    }
  }
}
