import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { createErrorHandler, TraceService } from '@sentry/angular-ivy';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayoutModule } from '../layout/layout.module';
import { NotificationsModule } from '../notifications';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent, CommonLayoutComponent, ManagersLayoutComponent, NotFoundPageComponent } from './pages';
import { AccountModule } from '../account/account.module';
import { environment } from '../environments/environment';

const providers = environment.production ? [
  { provide: ErrorHandler, useValue: createErrorHandler({ showDialog: false }) },
  { provide: TraceService, deps: [Router] },
  { provide: APP_INITIALIZER, useFactory: () => () => undefined, deps: [TraceService], multi: true }
] : [];

@NgModule({
  declarations: [
    AppComponent,
    NotFoundPageComponent,
    CommonLayoutComponent,
    ManagersLayoutComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    LayoutModule,
    NotificationsModule,
    AccountModule
  ],
  bootstrap: [AppComponent],
  providers
})
export class AppModule {
}
