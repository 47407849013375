import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AuthService } from '../../../auth/services';
import { RoutePath } from '../../../models';

@Component({
  selector: 'app-managers-layout',
  templateUrl: './managers-layout.component.html',
  styleUrls: ['./managers-layout.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ManagersLayoutComponent implements OnInit {
  candidates = `${RoutePath.candidates}`;
  questions = `${RoutePath.questions}`;
  sessions = `${RoutePath.sessions}`;
  managers = `${RoutePath.managers}`;

  ngOnInit() {
    this.auth.init();
  }

  constructor(public auth: AuthService) {
  }
}
