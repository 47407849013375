import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { Page, Pagination } from '../../../models';
import { pageNumberCalc } from '../../utils';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent {
  @Input() page: BehaviorSubject<Pagination> = new BehaviorSubject<Pagination>({ page: 1, per_page: 10 });
  @Input() total = 100;
  @Input() totalPages = 8;
  @Output() pageChange: EventEmitter<number> = new EventEmitter<number>();

  get pages(): Page[] {
    const pages: Page[] = [];
    const { page, per_page } = this.page.value;
    const totalPages = Math.ceil(this.total / per_page);
    const halfWay = Math.ceil(this.totalPages / 2);
    const isStart = page <= halfWay;
    const isEnd = totalPages - halfWay < page;
    const isMiddle = !isStart && !isEnd;
    const ellipsesNeeded = this.totalPages < totalPages;
    let i = 1;
    while (i <= totalPages && i <= this.totalPages) {
      let label;
      let sfx = '';
      const pageNumber = pageNumberCalc(i, page, this.totalPages, totalPages);
      const openingEllipsesNeeded = (i === 2 && (isMiddle || isEnd));
      const closingEllipsesNeeded = (i === this.totalPages - 1 && (isMiddle || isStart));
      if (ellipsesNeeded && (openingEllipsesNeeded || closingEllipsesNeeded)) {
        label = '...';
        sfx = 'separator';
      } else {
        label = pageNumber;
      }
      pages.push({ label: label.toString(), value: pageNumber, sfx });
      i++;
    }
    return pages;
  }

  get hasPages(): boolean {
    return this.pages?.length > 1;
  }

  get prevDisabled(): boolean {
    return this.page.value.page === 1;
  }

  get nextDisabled(): boolean {
    const { page, per_page } = this.page.value;
    return page >= Math.ceil(this.total / per_page);
  }

  trackByFn = (i: number, item: any) => item ?? i;

  pageSelect(page: Page): void {
    if (this.page.value.page !== page.value) {
      this.pageChange.emit(page.value);
    }
  }

  isSelected(page: Page): Observable<boolean> {
    return this.page.pipe(map(i => i.page === page.value));
  }

  pagePrev(): void {
    if (this.page.value.page > 1) {
      this.pageChange.emit(this.page.value.page - 1);
    }
  }

  pageNext(): void {
    const { page, per_page } = this.page.value;
    if (page < Math.ceil(this.total / per_page)) {
      this.pageChange.emit(page + 1);
    }
  }
}
