import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { filter, interval, SubscriptionLike, tap } from 'rxjs';
import { itemUnsubscribe } from '../../../utils';
import { NotificationTemplateDirective } from '../../directives';
import { NotificationContent, NotificationEvent } from '../../models';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent implements OnInit, OnDestroy {
  @Input() id: number;
  @Input() item: NotificationEvent;
  @Output() closeNotification = new EventEmitter<number>();
  @ViewChild(NotificationTemplateDirective, { static: true }) template!: NotificationTemplateDirective;
  private sub: SubscriptionLike[] = [];
  private period = 3000;

  get isContentText(): boolean {
    return !this.item.contentType || this.item.contentType === NotificationContent.text;
  }

  get icon(): string {
    return `/assets/icons/notification-${this.item.type}.svg`;
  }

  onClose(): void {
    this.closeNotification.emit();
  }

  ngOnInit(): void {
    if (this.item.contentType === NotificationContent.template) {
      this.loadComponent();
    }
    this.sub.push(interval(this.period).pipe(filter(i => !this.item.close || i > 3),
      tap(() => this.onClose())).subscribe());
  }

  ngOnDestroy(): void {
    itemUnsubscribe(this.sub);
  }

  loadComponent(): void {
    const viewContainerRef = this.template.viewContainerRef;
    viewContainerRef.clear();
    const componentRef = viewContainerRef.createComponent<typeof this.item.template>(this.item.template);
    componentRef.instance.data = { ...this.item.data, id: this.id };
  }
}
