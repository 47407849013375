<header class='flex flex-row gap-[12px] h-auto py-4 px-6 mx-auto justify-between'>
  <div class='flex flex-row gap-[8px] items-center'>
    <a class='flex flex-row cursor-pointer text-dark hover:text-dark' href='/'>
      <svg-icon src='/assets/icons/logo.svg'></svg-icon>
      <h1 class='text-[20px] ml-3'><strong class='mr-1'>Neon</strong><span>Task</span></h1>
    </a>
    <ng-content select="[left]"></ng-content>
  </div>
  <div class='flex flex-row gap-[8px] items-center'>
    <ng-content select="[center]"></ng-content>
  </div>
  <div class='flex flex-row items-center justify-end gap-[8px]'>
    <ng-content></ng-content>
  </div>
</header>
