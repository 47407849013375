import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Params } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  AuthData,
  Candidate,
  CandidateCreate,
  ContentId,
  LoginData,
  Question,
  QuestionCreate,
  QuestionDescription,
  QuestionDescriptionCreate,
  RegistrationData,
  Session,
  SessionCreate,
  SessionResult,
  SessionResultSubmit,
  SessionTokenString
} from '../models';

@Injectable({ providedIn: 'root' })
export class ApiService {
  private api = `${environment.urls.host}/api`;

  questionDescriptions(params?: Params): Observable<QuestionDescription[]> {
    return this.http.get<QuestionDescription[]>(`${this.api}/questions/descriptions`, { params });
  }

  questionDescriptionCreate(data: QuestionDescriptionCreate): Observable<QuestionDescription> {
    return this.http.post<QuestionDescription>(`${this.api}/questions/descriptions`, data);
  }

  questionDescriptionUpdate(id: number, data: QuestionDescriptionCreate): Observable<QuestionDescription> {
    return this.http.put<QuestionDescription>(`${this.api}/questions/descriptions/${id}`, data);
  }

  questionCreate(data: QuestionCreate): Observable<Question> {
    return this.http.post<Question>(`${this.api}/questions`, data);
  }

  questionUpdate(id: ContentId, data: QuestionCreate): Observable<Question> {
    return this.http.put<Question>(`${this.api}/questions/${id}`, data);
  }

  candidateCreate(data: CandidateCreate): Observable<Candidate> {
    return this.http.post<Candidate>(`${this.api}/candidates`, data);
  }

  candidateUpdate(id: ContentId, data: CandidateCreate): Observable<Candidate> {
    return this.http.put<Candidate>(`${this.api}/candidates/${id}`, data);
  }

  sessionCreate(data: SessionCreate): Observable<Session> {
    return this.http.post<Session>(`${this.api}/sessions`, data);
  }

  sessionState(session: SessionTokenString): Observable<Session> {
    return this.http.get<Session>(`${this.api}/sessions/state`, { params: { session } });
  }

  sessionStart(session: SessionTokenString): Observable<SessionResult> {
    return this.http.post<SessionResult>(`${this.api}/results`, null, { headers: { SessionToken: session } });
  }

  session(session: SessionTokenString): Observable<SessionResult> {
    return this.http.get<SessionResult>(`${this.api}/results/current`, { params: { session } });
  }

  sessionResults(params?: Params): Observable<SessionResult[]> {
    return this.http.get<SessionResult[]>(`${this.api}/results`, { params });
  }

  sessionResult(id: ContentId): Observable<SessionResult> {
    return this.http.get<SessionResult>(`${this.api}/results/${id}`);
  }

  candidateResults(id: ContentId, params?: Params): Observable<SessionResult[]> {
    return this.http.get<SessionResult[]>(`${this.api}/results/candidate/${id}`, { params });
  }

  sessionResultBySession(id: ContentId): Observable<SessionResult> {
    return this.http.get<SessionResult>(`${this.api}/results/session/${id}`);
  }

  sessions(params?: Params): Observable<Session[]> {
    return this.http.get<Session[]>(`${this.api}/sessions`, { params });
  }

  candidateSessions(id: ContentId, params?: Params): Observable<Session[]> {
    return this.http.get<Session[]>(`${this.api}/sessions/candidate/${id}`, { params });
  }

  sessionUpdate(session: SessionTokenString, body: SessionResultSubmit): Observable<SessionResult> {
    return this.http.put<SessionResult>(`${this.api}/results`, body, { headers: { SessionToken: session } });
  }

  sessionComplete(session: SessionTokenString, body: SessionResultSubmit): Observable<SessionResult> {
    return this.http.post<SessionResult>(`${this.api}/results/submit`, body, { headers: { SessionToken: session } });
  }

  question(id: ContentId): Observable<Question> {
    return this.http.get<Question>(`${this.api}/questions/${id}`);
  }

  candidate(id: ContentId): Observable<Candidate> {
    return this.http.get<Candidate>(`${this.api}/candidates/${id}`);
  }

  candidateSession(id: ContentId): Observable<SessionResult> {
    return this.http.get<SessionResult>(`${this.api}/sessions/${id}`);
  }

  questions(params?: Params): Observable<Question[]> {
    return this.http.get<Question[]>(`${this.api}/questions`, { params });
  }

  candidates(params?: Params): Observable<Candidate[]> {
    return this.http.get<Candidate[]>(`${this.api}/candidates`, { params });
  }

  login(data: LoginData): Observable<AuthData> {
    return this.http.post<AuthData>(`${this.api}/auth/login`, data);
  }

  registration(data: RegistrationData): Observable<AuthData> {
    return this.http.post<AuthData>(`${this.api}/auth/registration`, data);
  }

  authState(headers: Params): Observable<AuthData> {
    return this.http.get<AuthData>(`${this.api}/auth/state`, { headers });
  }

  constructor(private http: HttpClient) {
  }
}
