import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccountComponent } from './components';
import { SharedModule } from '../shared';

const components = [AccountComponent];


@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ]
})
export class AccountModule {
}
