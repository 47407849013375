import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-text',
  templateUrl: './loading-text.component.html',
  styleUrls: ['./loading-text.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingTextComponent implements OnInit {
  @Input() rows = 6;
  items: unknown[] = [];

  width(_: unknown): string {
    const max = 100;
    const min = 60;
    return `width: ${Math.floor(Math.random() * (max - min)) + min}%`;
  }

  ngOnInit() {
    this.items = Array(this.rows);
  }
}
