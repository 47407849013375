<div class='dialog-header gap-[16px]'>
  <h1 class='text-[18px]'>{{data.title}}</h1>
  <a (click)='close()' class='dialog-close'>
    <svg-icon src='/assets/icons/close.svg'></svg-icon>
  </a>
</div>
<div class='dialog-content'>
  <p class='text-[16px] leading-[18px]' *ngIf='data.description'>{{data.description}}</p>
  <div class='flex flex-row gap-[18px]'>
    <button class='dark-button p-[8px]' (click)='confirm()'>{{data.confirmText}}</button>
    <button class='white-button p-[8px]' (click)='close()'>{{data.cancelText}}</button>
  </div>
</div>
