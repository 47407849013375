import { AES, enc, SHA256 } from 'crypto-js';
import { isObject } from './parse';

export class ToCrypto {
  private readonly _keyPass: string;

  get keyPass(): string {
    return this._keyPass;
  }

  encrypt = (text: string, keyPass: string): string => AES.encrypt(text, keyPass).toString();

  decrypt = (encryptText: string, keyPass: string): string => AES.decrypt(encryptText.trim(), keyPass).toString(enc.Utf8);

  parse<T>(encryptText: string): T {
    try {
      return JSON.parse(isObject(encryptText) ? encryptText : this.decrypt(encryptText, this.keyPass));
    } catch (e: unknown) {
      console.error(e instanceof Error ? `${e.message}\n` : e);
    }
    return {} as T;
  }

  stringify<T>(value: T, ...replacer: any[]): string {
    return this.encrypt(JSON.stringify(value, ...replacer), this.keyPass);
  }

  private generateKeyPass = (sold = ''): string => {
    const {
      appCodeName,
      appName,
      appVersion,
      doNotTrack,
      platform,
      product,
      productSub,
      vendor,
      userAgent
    } = window.navigator;
    const key = [appCodeName, appName, appVersion, doNotTrack, platform, product, productSub, vendor, userAgent, sold].join('');
    return SHA256(key).toString();
  };

  constructor(keyPass = '') {
    this._keyPass = this.generateKeyPass(keyPass);
  }
}
