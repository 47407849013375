export function isObject(text: string): boolean {
  try {
    const result = JSON.parse(text);
    return typeof result === 'object' && result !== null;
  } catch (e) {
    return false;
  }
}

export function parseJson<T>(text: string): T {
  if (isObject(text)) {
    try {
      return JSON.parse(text);
    } catch (e: unknown) {
      console.log(e instanceof Error ? e.message : e);
    }
  }
  return {} as T;
}

export function isOther(type: string): boolean {
  return type.toLowerCase() === 'others' ||
    type.toLowerCase() === 'other' ||
    type.toLowerCase() === 'другие' ||
    type.toLowerCase() === 'другой' ||
    type.toLowerCase() === 'другая' ||
    type.toLowerCase() === 'другое';
}

export function calculatePageNumber(i: number, currentPage: number, paginationRange: number, totalPages: number): number {
  let halfWay = Math.ceil(paginationRange / 2);
  if (i === paginationRange) {
    return totalPages;
  } else if (i === 1) {
    return i;
  } else if (paginationRange < totalPages) {
    if (totalPages - halfWay < currentPage) {
      return totalPages - paginationRange + i;
    } else if (halfWay < currentPage) {
      return currentPage - halfWay + i;
    } else {
      return i;
    }
  } else {
    return i;
  }
}
