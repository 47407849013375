import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { isAuthenticated, isUnAuthenticated } from './guards';
import { NotFoundPageComponent } from './pages';

const routes: Routes = [
  { path: '', redirectTo: 'm', pathMatch: 'full' },
  {
    path: 'm', children: [
      {
        path: '',
        canActivate: [isAuthenticated],
        loadChildren: () => import('../managers/managers.module').then(m => m.ManagersModule)
      },
      {
        path: '',
        canActivate: [isUnAuthenticated],
        loadChildren: () => import('../auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  { path: 'q', loadChildren: () => import('../task/task.module').then(m => m.TaskModule) },
  { path: '**', component: NotFoundPageComponent }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
