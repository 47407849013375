import { inject } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from '../../auth/services';
import { RoutePath } from '../../models';

export const isUnAuthenticated = (): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isAuthenticated$.pipe(
    take(1),
    map((isAuthenticated: boolean) => !isAuthenticated),
    tap((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        router.navigate([RoutePath.root]);
      }
    }));
};

export const isAuthenticated = (): | boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> => {
  const authService = inject(AuthService);
  const router = inject(Router);
  return authService.isAuthenticated$.pipe(
    take(1),
    tap((isAuthenticated: boolean) => {
      if (!isAuthenticated) {
        router.navigate([RoutePath.login]);
      }
    })
  );
};
