import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-content',
  templateUrl: './loading-content.component.html',
  styleUrls: ['./loading-content.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingContentComponent implements OnInit {
  @Input() rows = 5;
  @Input() height = 50;
  @Input() gap = 8;
  @Input() horizontal = false;
  items: unknown[] = [];

  get styles(): string {
    return `height: ${this.height}px;`;
  }

  get wrapStyles(): string {
    return `flex-direction:${this.horizontal ? 'row' : 'column'};gap:${this.gap}px`;
  }

  ngOnInit() {
    this.items = Array(this.rows);
  }
}
