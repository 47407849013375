import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogModule } from '@angular/cdk/dialog';
import { FormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import {
  AvatarComponent,
  ConfirmDialogComponent,
  EmptyContentComponent,
  LoaderSmallComponent,
  LoadingContentComponent,
  LoadingTextComponent,
  MarkdownContentComponent,
  PaginationComponent,
  SearchComponent,
  TooltipComponent
} from './components';
import { MarkdownPipe } from './pipes';
import { ConfirmDialogService } from './services';
import { DropdownDirective, TooltipDirective } from './directives';

const components = [AvatarComponent, ConfirmDialogComponent, EmptyContentComponent, LoaderSmallComponent,
  LoadingContentComponent, LoadingTextComponent, MarkdownContentComponent, PaginationComponent, TooltipComponent, SearchComponent];
const directives = [DropdownDirective, TooltipDirective];
const pipes = [MarkdownPipe];

@NgModule({
  imports: [CommonModule, FormsModule, AngularSvgIconModule, DialogModule],
  exports: [...components, ...directives, ...pipes],
  declarations: [...components, ...directives, ...pipes],
  providers: [ConfirmDialogService]
})
export class SharedModule {
}
