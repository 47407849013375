import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import './polyfills';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { sentryInit } from './sentry';

if (environment.production) {
  enableProdMode();
  sentryInit();
}
platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
