import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-small',
  templateUrl: './loader-small.component.html',
  styleUrls: ['./loader-small.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderSmallComponent {
  @Input() color = `#E3E3E3`;
}
