import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { of } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SearchComponent {
  @Input() loading = of(false);
  @Output() search = new EventEmitter<{ [key: string]: string }>();
  data = '';

  onSearch(search: string): void {
    this.data = search;
    this.search.next({ search: this.data });
  }
}
