import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { NotificationContent, NotificationEvent, NotificationType } from '../models';

@Injectable()
export class NotificationService {
  max = 5;
  private _notifications: NotificationEvent[] = [];
  private _notifications$ = new ReplaySubject<NotificationEvent[]>(0);

  get notifications(): NotificationEvent[] {
    return this._notifications;
  }

  get notifications$(): Observable<NotificationEvent[]> {
    return this._notifications$;
  }

  get eventDefault(): NotificationEvent {
    return { title: '', state: 'fade', close: true };
  }

  success(data: NotificationEvent): void {
    this.notificationPush({ close: false, type: NotificationType.success, ...data });
  }

  error(data: NotificationEvent): void {
    this.notificationPush({ ...data, type: NotificationType.error });
  }

  info(data: NotificationEvent): void {
    this.notificationPush({ ...data, type: NotificationType.info });
  }

  template<T = any>(template: T): void {
    const data: NotificationEvent = {
      type: NotificationType.info,
      contentType: NotificationContent.template,
      template,
      title: '',
      close: true
    };
    this.notificationPush(data);
  }

  close(index: number): void {
    this._notifications.splice(index, 1);
    this._notifications$.next(this._notifications);
  }

  private notificationPush(data: NotificationEvent): void {
    const notification = { ...this.eventDefault, ...data };
    if (this._notifications?.length >= this.max) {
      this._notifications.pop();
    }
    this._notifications.unshift(notification);
    this._notifications$.next(this._notifications);
  }
}
