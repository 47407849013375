import { NgModule, Provider } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './interseptors/auth.interceptor';
import { ApiService } from './services/api.service';

export const API_INTERCEPTOR: Provider = { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true };

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  exports: [HttpClientModule],
  providers: [API_INTERCEPTOR, ApiService]
})
export class ApiModule {
}
