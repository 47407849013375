<ng-container *ngIf='loading|async; else showMarkdownContent'>
  <app-loading-content [rows]='1' [height]='32'></app-loading-content>
  <app-loading-text [rows]='5'></app-loading-text>
</ng-container>
<ng-template #showMarkdownContent>
  <h2 class='text-[22px] leading-[24px] mb-[12px] font-bold'>{{title}}</h2>
  <div [innerHTML]='showDescription|markdown' class='md-content' [ngClass]='descriptionBlur'></div>
  <button *ngIf='showControl' (click)='onHandleFullDescription()' class='white-button small max-w-[100px] mb-[10px]'>{{fullContentText}}</button>
  <h3 class='text-[16px] leading-[18px] mb-[6px] font-bold'>Question:</h3>
  <div class='md-content' [innerHTML]='content|markdown'></div>
</ng-template>
