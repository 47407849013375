import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { Pagination } from '../../models';

@Injectable({ providedIn: 'root' })
export class FilterService<P> {
  private _filters: { [key: string]: P } = {};
  private _filters$: ReplaySubject<{ [key: string]: P }> = new ReplaySubject<{ [key: string]: P }>(1);
  private emptyFilterData: any[] = ['', undefined, null, NaN];

  get filters(): { [key: string]: P } {
    return this._filters;
  }

  set filters(data: { [key: string]: P }) {
    this.filtersDataEmmit(data);
  }

  get filters$(): Observable<{ [key: string]: P }> {
    return this._filters$;
  }

  public filterMethod = <T>(method: (params: P) => Observable<T>, key: string, filters?: P): Observable<T> => {
    if (typeof filters === 'object' && filters !== null) {
      if (!this._filters.hasOwnProperty(key)) {
        this._filters[key] = {} as P;
      }
      // if (!filters.hasOwnProperty('order_by')) {
      //   // @ts-ignore
      //   filters['order_by'] = `updated_at`;
      // }
      for (const filterKey in filters) {
        if (filters.hasOwnProperty(filterKey)) {
          if (this.emptyFilterData.includes(filters[filterKey])) {
            delete this._filters[key][filterKey];
          } else {
            this._filters[key][filterKey] = filters[filterKey];
          }
        }
      }
      this._filters$.next(this._filters);
    }
    return method(this.filters[key]);
  };

  public calculateTotal = <T>(data: T, pageQuery: Pagination): number => {
    const { page, per_page } = pageQuery;
    const currentCounts = (Array.isArray(data) ? data.length : 0) + (page - 1) * per_page;
    const maxCounts = per_page * page;
    return currentCounts < maxCounts ? currentCounts : maxCounts + 1;
  };

  public clear = (key: string): void => {
    this._filters[key] = {} as P;
    this._filters$.next(this._filters);
  };

  private filtersDataEmmit = (data: any): void => {
    this._filters = data;
    this._filters$.next(data);
  };
}
