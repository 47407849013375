import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { AngularSvgIconModule } from 'angular-svg-icon';

import { HeaderComponent } from './components/header/header.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';

const components = [
  HeaderComponent,
  ContentComponent,
  FooterComponent
];

@NgModule({
  declarations: [...components],
  exports: [...components],
  imports: [CommonModule, AngularSvgIconModule, RouterLink]
})
export class LayoutModule {
}
