import { TrackByFunction } from '@angular/core';

export function trackByFactory<T>(key: keyof T): TrackByFunction<T> {
  return (i, item) => item[key] ?? i;
}

export function collectionItemUpdate<T>(items: T[], item: T, key: keyof T, method: 'push' | 'unshift' = 'push'): T[] {
  const data: T[] = items ?? [];
  const index = data.findIndex(i => i[key] === item[key]);
  if (index > -1) {
    data[index] = item;
  } else {
    data[method](item);
  }
  return data;
}

export function collectionItemDelete<T>(items: T[], item: T, key: keyof T): T[] {
  const data: T[] = items ?? [];
  const index = data.findIndex(i => i[key] === item[key]);
  if (index > -1) {
    data.splice(index, 1);
  }
  return data;
}
