import { ComponentRef, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Overlay, OverlayPositionBuilder, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { SafeHtml } from '@angular/platform-browser';
import { TooltipComponent } from '../components';

@Directive({ selector: '[tooltip]' })
export class TooltipDirective implements OnInit, OnDestroy {
  @Input('tooltip') content: SafeHtml = '';
  private ref: OverlayRef;

  @HostListener('mouseover')
  show(): void {
    if (!this.ref.hasAttached()) {
      const tooltipRef: ComponentRef<TooltipComponent> = this.ref.attach(new ComponentPortal(TooltipComponent));
      tooltipRef.instance.content = this.content as string;
    }
  }

  @HostListener('mouseout')
  hide(): void {
    this.ref.detach();
  }

  ngOnInit(): void {
    const positionStrategy = this.opb.flexibleConnectedTo(this.elementRef)
      .withPositions([{
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetX: 0,
        offsetY: -6
      }]);

    this.ref = this.overlay.create({ positionStrategy });
  }

  ngOnDestroy(): void {
    if (this.ref) {
      this.ref.detach();
    }
  }

  constructor(private overlay: Overlay, private opb: OverlayPositionBuilder, private elementRef: ElementRef) {
  }
}
